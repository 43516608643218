/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import ApplicationError, {type ApplicationErrorOptions} from './Application';
import type {RequestInfo} from './Request';

interface RequestAbortErrorOptions extends ApplicationErrorOptions {
  request?: RequestInfo;
}

/**
 * Request fetch error
 */
export default class RequestAbortError extends ApplicationError {
  static type = Symbol.for('requestAbort');

  constructor(options: RequestAbortErrorOptions | string = {}) {
    if (typeof options === 'string') {
      options = {message: options};
    }

    const {...details} = options;

    _.defaults(details, {});

    super(details);
    this.name = 'RequestAbortError';
  }
}
