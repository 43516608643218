/**
 * Copyright 2024 Illumio, Inc. All Rights Reserved.
 */

import ApplicationError, {type ApplicationErrorOptions} from './Application';
import {StatusCodes} from 'http-status-codes';

export interface InternalServerErrorOptions extends ApplicationErrorOptions {
  // any extra options
}

export default class InternalServerError extends ApplicationError {
  constructor(options: InternalServerErrorOptions | string = {}) {
    if (typeof options === 'string') {
      options = {message: options};
    }

    super({
      ...options,
      httpStatus: StatusCodes.INTERNAL_SERVER_ERROR,
      grpcStatus: 13,
    });
    this.name = 'InternalServerError';
  }
}
