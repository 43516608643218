/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import ApplicationError, {type ApplicationErrorOptions} from './Application';
import {hrefUtils} from '@illumio-shared/utils/shared';
import {StatusCodes} from 'http-status-codes';

type UnknownRecord = Record<string, unknown>;

interface RedirectErrorOptions extends ApplicationErrorOptions {
  to?: string;
  params?: UnknownRecord;
  query?: UnknownRecord;
  proceedFetching?: boolean;
  thisFetchIsDone?: boolean;
}

/**
 * Request fetch error
 */
export default class RedirectError extends ApplicationError {
  static type = Symbol.for('redirect');

  to: string;
  params: UnknownRecord;
  query: UnknownRecord;

  constructor(options: RedirectErrorOptions | string = {}) {
    if (typeof options === 'string') {
      options = {message: options};
    }

    const {to = '', params = {}, query = {}, ...details} = options;

    _.defaults(details, {
      httpStatus: StatusCodes.MOVED_TEMPORARILY,
    });

    super(details);
    this.name = 'RedirectError';

    this.to = to;
    this.params = hrefUtils.processParams(params);
    this.query = query;
  }

  toString(): string {
    const messages = [`Redirect to "${this.to}"`];
    const extra: string[] = [];

    if (!_.isEmpty(this.params)) {
      extra.push(`params ${JSON.stringify(this.params)}`);
    }

    if (!_.isEmpty(this.query)) {
      extra.push(`query ${JSON.stringify(this.query)}`);
    }

    const extraMessage = extra.join(' and ');

    if (extraMessage) {
      messages.push(extraMessage);
    }

    return messages.join(' with ');
  }
}
