/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import {StatusCodes} from 'http-status-codes';
import {v4 as uuidv4} from 'uuid';
import type {status} from '@grpc/grpc-js';

const FIREFOX_ERROR_INFO = /@(.+?):(\d+):(\d+)$/;
const errorMsgs: Record<string, string> = {};

export type ApplicationErrorJSONRepresent = Pick<
  ApplicationError,
  'errorId' | 'grpcStatus' | 'httpStatus' | 'message'
> & {
  type: ApplicationError['name'];
  details?: Record<string, unknown>;
};

export interface ApplicationErrorOptions {
  type?: string;
  message?: string;
  trace?: boolean;
  errorId?: string;
  httpStatus?: StatusCodes;
  grpcStatus?: Exclude<status, status.OK>;
  details?: Record<string, unknown>;
}

/**
 * Thor main error type
 */
export default class ApplicationError extends Error {
  static type = Symbol.for('application');

  trace: boolean;
  details: Record<string, unknown>;
  errorId?: string;
  httpStatus: StatusCodes;
  grpcStatus?: Exclude<status, status.OK>;

  fileName?: string;
  lineNumber?: number;
  columnNumber?: number;

  static didProduce<T extends typeof ApplicationError>(this: T, error: unknown): error is InstanceType<T> {
    return error instanceof Error && (error.constructor as typeof ApplicationError).type === this.type;
  }

  constructor(options: ApplicationErrorOptions | string = {}) {
    if (typeof options === 'string') {
      options = {message: options};
    } else if (typeof options !== 'object') {
      throw new TypeError(`ApplicationError options must be an object or string, now it is '${typeof options}'`);
    }

    const {message, httpStatus = StatusCodes.INTERNAL_SERVER_ERROR, trace = true, grpcStatus = 2, ...details} = options;

    super(message || (details.type && errorMsgs[details.type])); // Native Error constructor accepts message
    this.name = 'ApplicationError';

    this.trace = trace;
    this.details = details as Record<string, unknown>;
    this.httpStatus = httpStatus;
    this.errorId = options.errorId ?? uuidv4();
    this.grpcStatus = grpcStatus;

    if (trace) {
      // Ensure we get a proper stack trace in most JavaScript environments
      if (typeof Error.captureStackTrace === 'function') {
        // V8 environments (Chrome and Node.js)
        Error.captureStackTrace(this, ApplicationError);
      } else {
        // Firefox workaround
        const {stack} = new Error(this.message);

        if (stack) {
          // Skipping the first line in stack (it's the line where we have created our `new Error`)
          const stacks = stack.split('\n').slice(1);

          // Trying to get file name, line number and column number from the first line in stack
          const [, fileName, lineNumber, columnNumber] = FIREFOX_ERROR_INFO.exec(stacks[0] || '') || [];

          this.stack = stacks.join('\n');
          this.fileName = fileName || undefined;
          this.lineNumber = lineNumber ? Number(lineNumber) : undefined;
          this.columnNumber = columnNumber ? Number(columnNumber) : undefined;
        }
      }
    }
  }

  toJSON(): ApplicationErrorJSONRepresent {
    const result: ApplicationErrorJSONRepresent = {
      type: this.name,
      message: this.message,
      httpStatus: this.httpStatus,
      errorId: this.errorId,
      grpcStatus: this.grpcStatus,
    };

    if (Object.keys(this.details).length > 0) {
      result.details = this.details;
    }

    return result;
  }
}
