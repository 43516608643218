/**
 * Copyright 2024 Illumio, Inc. All Rights Reserved.
 */

import ApplicationError, {type ApplicationErrorOptions} from './Application';
import {StatusCodes} from 'http-status-codes';

export interface NotFoundErrorOptions extends ApplicationErrorOptions {
  // any extra options
}

export default class NotFoundError extends ApplicationError {
  constructor(options: NotFoundErrorOptions | string = {}) {
    if (typeof options === 'string') {
      options = {message: options};
    }

    super({
      ...options,
      httpStatus: StatusCodes.NOT_FOUND,
      grpcStatus: 5,
    });
    this.name = 'NotFoundError';
  }
}
